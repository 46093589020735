module.exports = {
  ACTION_TYPES: {
    SET_ACTIVE_SLIDE: 'SET_ACTIVE_SLIDE',
    SET_CELIA_VERTICAL_POSITION: 'SET_CELIA_VERTICAL_POSITION',
    SET_ANIMATION_FRAME: 'SET_ANIMATION_FRAME',
    SET_CELIA_VERTICAL_DIRECTION: 'SET_CELIA_VERTICAL_DIRECTION',
    SET_ANIMATION_MAX_HEIGHT: 'SET_ANIMATION_MAX_HEIGHT',
    SET_IS_MOBILE: 'SET_IS_MOBILE',
  },
  CELIA_FRAMES_POSITION: {
    FRONT: 4,
    FRONT_HELLO: -10.65,
    BACK_ONE: -27.4,
    BACK_RIGHT_SIDE: -42.5,
    BACK_LEFT_SIDE: -58.7,
    BACK_TWO: -74.5,
    BACK_THREE: -90.3,
    SIT_ONE: -109.1,
    SIT_TWO: -128,
  },
  CELIA_ANIMATION_FRAMES: {
    IDLE: 'IDLE',
    FRONT: 'FRONT',
    FRONT_HELLO: 'FRONT_HELLO',
    BACK_ONE: 'BACK_ONE',
    BACK_RIGHT_SIDE: 'BACK_RIGHT_SIDE',
    BACK_LEFT_SIDE: 'BACK_LEFT_SIDE',
    BACK_TWO: 'BACK_TWO',
    BACK_THREE: 'BACK_THREE',
    SIT_ONE: 'SIT_ONE',
    SIT_TWO: 'SIT_TWO',
  },
  CELIA_VERTICAL_POSITION: {
    IDLE: 'IDLE',
    TOP: 'TOP',
    TRANSITIONING: 'TRANSITIONING',
    BOTTOM: 'BOTTOM',
  },
  CELIA_VERTICAL_DIRECTION: {
    IDLE: 'IDLE',
    TO_TOP: 'TO_TOP',
    TO_BOTTOM: 'TO_BOTTOM',
  },
}
